import Enum from '@/utils/Enum';

const BusinessScenarioEnumList = [
  {
    value: 'MAINTENANCE_SCENARIO',
    name: '维修场景',
  },
  {
    value: 'UPKEEP_SCENARIO',
    name: '保养场景',
  },
];

export default new Enum(BusinessScenarioEnumList);
export { BusinessScenarioEnumList };
