<template>
  <div>
    <content-block>
      <content-block title="基本信息">
        <el-descriptions :column="3" size="normal" :labelStyle="labelStyle">
          <el-descriptions-item label="姓名">{{ form.name }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{ form.phoneNumber }}</el-descriptions-item>
          <el-descriptions-item label="工号">{{ form.jobNumber }}</el-descriptions-item>
          <el-descriptions-item label="邮箱地址">{{ form.email }}</el-descriptions-item>
          <el-descriptions-item label="角色权限">{{ form.roleNamesStr }}</el-descriptions-item>
          <el-descriptions-item label="员工状态">{{ form.statusName }}</el-descriptions-item>
          <el-descriptions-item label="工作状态">
            <span style="color: #afafb1">
              {{
                form.workStatus === 'VACATION'
                  ? `${form.oldWorkStatusName}（${form.workStatusName}）`
                  : form.workStatusName
              }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions class="content_block" :column="3" size="normal" :labelStyle="labelStyle">
          <el-descriptions-item label="标签">
            <el-tooltip
              v-for="tag in form.labelList || []"
              :key="tag.labelId"
              effect="dark"
              :content="tag.remark"
              placement="top-start"
            >
              <el-tag size="small" style="margin: 0 6px 6px 0">
                {{ tag.name }}
              </el-tag>
            </el-tooltip>
          </el-descriptions-item>
        </el-descriptions>
      </content-block>
      <content-block title="账号信息">
        <el-descriptions class="content_block bk_lgray" :column="3" size="normal">
          <el-descriptions-item label="账号名称">{{ form.phoneNumber }}</el-descriptions-item>
          <el-descriptions-item label="激活状态">
            <el-switch
              disabled
              :value="form.accountStatus"
              :active-text="form.accountStatusName"
              active-value="ACTIVE"
            ></el-switch>
          </el-descriptions-item>
        </el-descriptions>
      </content-block>
    </content-block>
    <content-block>
      <el-tabs v-model="activeName">
        <el-tab-pane label="员工动态" name="employeeDynamic">
          <employee-dynamic-tb :id="id"></employee-dynamic-tb>
        </el-tab-pane>
        <el-tab-pane label="工单" name="workOrder">
          <work-order-tb :id="id"></work-order-tb>
        </el-tab-pane>
        <el-tab-pane label="设备服务能力" name="equipmentServiceCapability">
          <equipmentServiceCapabilityTb :id="id"></equipmentServiceCapabilityTb>
        </el-tab-pane>
      </el-tabs>
    </content-block>
    <bottom-panel>
      <template>
        <el-button style="margin-left: 1em" type="danger" @click="back">返回</el-button>
        <el-button type="primary" @click="update">修改</el-button>
      </template>
    </bottom-panel>
  </div>
</template>
<script>
import SystemSetting from '@/api/systemSetting';
import employeeDynamicTb from './components/employeeDynamicTb.vue';
import workOrderTb from './components/workOrderTb.vue';
import equipmentServiceCapabilityTb from './components/equipmentServiceCapabilityTb.vue';

export default {
  name: 'EMPLOYEE_DETAIL',
  components: { employeeDynamicTb, workOrderTb, equipmentServiceCapabilityTb },
  props: {
    id: {
      type: String,
    },
  },
  data () {
    return {
      form: {},
      labelStyle: { width: '5em', textAlign: 'right' },
      activeName: 'employeeDynamic',
    };
  },
  methods: {
    getDetail () {
      SystemSetting.getDetailEmploy({
        id: this.id,
        findDepartmentFlag: true,
        findRoleFlag: true,
      }).then(res => {
        this.form = res.body;
      });
    },
    back (refresh = false) {
      this.$tabs.close({
        to: '/systemSetting/employee/list',
        refresh,
      });
    },
    update () {
      this.$router.push({
        name: 'EmployeeEdit',
        query: {
          id: this.id,
        },
      });
    },
  },
  created () {
    this.getDetail();
  },
};
</script>
<style lang="scss" scoped></style>
