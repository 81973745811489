import Enum from '@/utils/Enum';

const DynamicEnumList = [
  {
    value: 'WORK_ORDER',
    name: '工单动态',
  },
  {
    value: 'WORK_STATUS',
    name: '工作状态',
  },
];

export default new Enum(DynamicEnumList);
export { DynamicEnumList };
