<template>
  <div>
    <div class="content_block">
      <query-form label-width="100px" :span="8">
        <template slot="action">
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button type="primary" size="mini" @click="exportExcel" style="margin-bottom: 20px">导出</el-button>
        </template>
        <el-form-item label-width="0" prop="type">
          <check-tag v-model="query.type" @change="handleSearch()">
            <check-tag-item
              :label="i.name"
              :value="i.value"
              :key="i.value"
              v-for="i in DynamicEnumList"
            ></check-tag-item>
          </check-tag>
        </el-form-item>
        <el-form-item label="日期范围">
          <el-date-picker
            v-model="query.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
      </query-form>
      <el-timeline v-infinite-scroll="loadMore" :infinite-scroll-disabled="disabled">
        <el-timeline-item v-for="v in tableData" :key="v.id" :timestamp="formatDate(v.time)" placement="top" color="#4daeff">
          <el-card>
            <el-tag :type="v.module === 'WORK_ORDER' ? 'success' : 'warning'" effect="dark">{{ DynamicEnum[v.module] }}</el-tag>
            <p style="color: #bbb7bb; margin-top: 8px;">{{ v.content }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
import OrderApi from '@/api/order';
import DynamicEnum, { DynamicEnumList } from '@/enums/DynamicEnum';
import { wrapWithAll } from '@/utils/Enum';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import dayjs from 'dayjs';

export default {
  name: 'EMPLOYEE_DYNAMIC_TB',
  mixins: [TablePaginationMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      DynamicEnum,
      DynamicEnumList: wrapWithAll(DynamicEnumList),
      query: {
        type: null,
        dateRange: ['', ''],
      },
      tableData: [],
      disabled: false,
    };
  },
  methods: {
    formatDate (d) {
      return dayjs(d || '').format('YYYY-MM-DD HH:mm:ss');
    },
    download ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    exportExcel () {
      OrderApi.exportDynamicInfoExcel({
        employeeId: this.id,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1],
        pageFlag: false,
        type: this.query.type,
      }).then(({ data }) => {
        this.download({
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `员工动态信息_${dayjs(Date.now()).format('YYYYMMDDHHmmss')}`,
        });
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    getTableList () {
      this.tableData = this.pagination.currentPage === 1 ? [] : this.tableData;

      if (this.pagination.currentPage !== 1 && this.disabled) {
        return;
      }

      OrderApi.getDynamicInfoForPageByEmployeeId({
        employeeId: this.id,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1],
        type: this.query.type,
        ...this.createTimeSortInfo,
        ...this.pagination,
        pageNum: this.pagination.currentPage,
      }).then(res => {
        this.tableData = [...this.tableData, ...(res.body?.list || [])];
        this.pagination.total = res.body?.total || 0;
        this.disabled = this.tableData.length >= this.pagination.total;
      });
    },
    loadMore () {
      this.pagination.currentPage++;
      this.getTableList();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-timeline-item__node {
  box-shadow: 0 0 5px 5px rgba(77, 174, 255, .5);
}
</style>
