import Enum from '@/utils/Enum';

const DataSourceEnumList = [
  {
    value: 'ADD_MANUALLY',
    name: '手工新增',
  },
  {
    value: 'WORK_ORDER_IMPORT',
    name: '工单引入',
  },
];

export default new Enum(DataSourceEnumList);
export { DataSourceEnumList };
